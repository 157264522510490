import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-aboutpage-history',
    templateUrl: './aboutpage-history.component.html',
    styleUrls: ['./aboutpage-history.component.scss']
})
export class AboutpageHistoryComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Tarihçe",
            title: 'Başlangıç - 2000',
            paragraphText: 'Hizmetlerimiz 2000 yılında başlamıştır.'
        }
    ]
    historyTimeline: TimelineBlock[] = [
        {
            year: '2000',
            date: 'Eylül 1',
            title: 'Kuruluş',
            paragraphText: 'Yazılımda ileri seviye kod geliştirme ve değer katma politikası ile yola çıkan firmamız kurulmuştur. Yazılım teknolojileri ile yapay zeka, veri bilimi ve makine öğrenmesi üzerine geliştirmelerde bulunarak teknolojiye yön vermeyi amaç edinmiştir.',
            img: 'assets/img/history/img1.jpg'
        },
        {
            year: '2005-2010',
            date: 'Haziran 10',
            title: 'Belediye, Tekstil, Turizm Yazılım Geliştirme',
            paragraphText: 'Belediye vergi tahsilatları, Tekstil kalite yazılımları ve Turizmde seyahat takip yönetimi yazılımları ile yeni bir soluk getirmiştir.',
            img: 'assets/img/history/img2.jpg'
        },
        {
            year: '2010-2015',
            date: 'Mart 1',
            title: 'Bankacılık, Finans, Telekomünikasyon Yazılım Geliştirme',
            paragraphText: 'Finans ve Bankacılık kurumlarında Alternatif Dağıtım kanalları üzerine (Kredi Kartları, Üye işyeri, ATM) ve internet bankacılık uygulamalarını geliştirmiştir. Telekomünikasyon sektöründe akış yönetimi projeleri geliştirmiştir. Veri bilimi ve veri analizleri ile büyük veri incelemelerinde bulunulmuştur.',
            img: 'assets/img/history/img3.jpg'
        },
        {
            year: '2015-2017',
            date: 'Nisan 16',
            title: 'Emlak, E-ticaret, Sanal Pazarlar, Lojistik Yazılım Geliştirme',
            paragraphText: 'E-ticaret paket yazılımları, sanal pazarlara entegrasyon yazılımları, emlak yönetimi yazılımları ile veri analizlerini yaparak trend yönlerine karar verici uygulamalar geliştirmiştir.',
            img: 'assets/img/history/img4.jpg'
        },
        {
            year: '2017- Halen',
            date: 'Ocak 5',
            title: 'Yapay zeka, Veri bilimi ve Büyük veri uygulamaları geliştirme',
            paragraphText: 'Yapay zeka kullanarak büyük verilerin analiz edilmesi grafiklerle anlaşılır hale getirilmesi projelerinin geliştirilmesini yapmaktadır.',
            img: 'assets/img/history/img1.jpg'
        } 
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class TimelineBlock {
    year : string;
    date : string;
    title : string;
    paragraphText : string;
    img : string;
}