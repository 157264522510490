import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-services-page',
    templateUrl: './services-page.component.html',
    styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Hizmetlerimiz'
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'flaticon-rocket',
            title: 'Trend Yönü Analizleri',
            paragraphText: 'Marketinizde ki ürün, pazar ve müşteri trendlerini yapay zeka algoritmalarıyla analizlerini yapar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'flaticon-laptop',
            title: 'Pazar-Marketing Analizleri',
            paragraphText: 'Pazar ve marketinizde trend yönü belirleyici analizleri yapay zeka algoritmalarıyla hazırlar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'flaticon-order',
            title: 'Müşteri Analizleri',
            paragraphText: 'Pazarınızdaki müşterilerin trend yönünü yapay zeka algoritmalarıyla analizlerini hazırlar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'flaticon-segmentation',
            title: 'Eticaret Analizleri',
            paragraphText: 'Eticaret ürünlerinizin, satışlarınızın trendini belirler. Size uygun ürünlerle satışınızı nasıl arttıracağınızı planlar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'flaticon-analytics',
            title: 'Satış Analizleri',
            paragraphText: 'Pazarınızda ki satışını yaptığınız ürünlerde trend hangi yöne gidiyor bunu belirler. Stratejinizle doğru hamleler yaptırır.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'flaticon-loupe',
            title: 'Risk Analizleri',
            paragraphText: 'Sektörenizde ki risklerin analizlerini yapay zeka algoritmalarıyla yapar. Risklerin trendini size gösterir.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'flaticon-settings',
            title: 'Sosyal Medya Analizleri',
            paragraphText: 'Facebook, instagram, twitter gibi sosyal medya mecralarında pazar, ürün ve müşteri analizlerini hazırlar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'flaticon-tracking',
            title: 'Gerçek Zamanlı Analizler',
            paragraphText: 'Kampanyaya ürünlerinizi hazırlar ve bu ürünlerin trend yönlerini kontrol ederek, satışlarınızı artırır.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'flaticon-price-tag',
            title: 'Data Analizleri',
            paragraphText: 'Veri setinizi yapay zeka algoritmalarımızdan geçirerek trend yönüne karar vermenizi sağlar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'flaticon-money',
            title: 'Data Görselleştirme',
            paragraphText: 'Yapay zeka algoritmalarımızla trend yönünü belirler, yönetim paneli ile grafikleri izlemenizi sağlar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'flaticon-structure',
            title: 'AI & ML Geliştirme',
            paragraphText: 'Yapay zeka ve makine öğrenmesi kullanarak istediğiniz veriye ait trend yönlerini belirlemenizi sağlar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'flaticon-tick',
            title: 'Veri Bilimi',
            paragraphText: 'Büyük veri ve veri setinizi kullanarak algoritmalar ile verinizi incelemenizi sağlar ve grafiklerle gösterimini yapar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'flaticon-idea',
            title: 'Yazılım Geliştirme',
            paragraphText: 'Trend yönü belirlenmesi ile size özel uygulama hazırlanarak yönetim ve planlama yapabileceğiniz uygulamalar geliştirilir.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        }
    ]

}
class pageTitle {
    title : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}