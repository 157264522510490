import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss']
})
export class HomeoneBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    mainBannerContent: Content[] = [
        {
            title: 'TRENDICTOR - Yapay Zeka ile Trend Analizi',
            paragraphText: 'Yapay Zeka Algoritmaları ile Market, Pazar, Ürün ve Müşteri analizlerini yapar ve TREND YÖN TAHMİNİ ile grafikleri hazırlar ve işinizi BÜYÜTÜR!',
            defaultBtnIcon: 'flaticon-structure',
            defaultBtnText: 'Hakkımızda',
            defaultBtnLink: 'about-us',
            videoBtnIcon: 'flaticon-google-play',
            videoBtnText: 'Video İzle',
            videoBtnLink: 'https://www.youtube.com/watch?v=xlgnWJx9oxc'
        }
    ]

}
class Content {
    title : string;
    paragraphText : string;
    defaultBtnIcon : string;
    defaultBtnText: string;
    defaultBtnLink : string;
    videoBtnIcon : string;
    videoBtnText: string;
    videoBtnLink : string;
}