import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-related-services',
    templateUrl: './related-services.component.html',
    styleUrls: ['./related-services.component.scss']
})
export class RelatedServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'İlgi Duyabileceğiniz Diğer Hizmetlerimiz'
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'assets/img/services/icon1.png',
            title: 'Data Analizleri',
            paragraphText: 'Veri setinizi yapay zeka algoritmalarımızdan geçirerek trend yönüne karar vermenizi sağlar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'assets/img/services/icon2.png',
            title: 'AI & ML Geliştirme',
            paragraphText: 'Yapay zeka ve makine öğrenmesi kullanarak istediğiniz veriye ait trend yönlerini belirlemenizi sağlar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'assets/img/services/icon3.png',
            title: 'Veri Bilimi',
            paragraphText: 'Büyük veri ve veri setinizi kullanarak algoritmalar ile verinizi incelemenizi sağlar ve grafiklerle gösterimini yapar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        }
    ]

}
class sectionTitleContent {
    title : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}