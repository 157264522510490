import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-services-details-page',
    templateUrl: './services-details-page.component.html',
    styleUrls: ['./services-details-page.component.scss']
})
export class ServicesDetailsPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Hizmetlerimiz',
            subTitle: 'Trendictor - Trend Yönünü Belirler',
        }
    ]
    servicesDetailsImage: DetailsImage[] = [
        {
            img: 'assets/img/services/services-details1.jpg'
        }
    ]
    servicesDetailsDesc: DetailsDesc[] = [
        {
            subTitle: 'Trendictor - Trend Yönünü Belirler',
            title1: 'Yapay zeka algoritmaları ile Trend yönünü belirler',
            paragraphText1: 'Yapay Zeka Algoritmaları ile Market, Pazar, Ürün ve Müşteri analizlerini yapar ve TREND YÖN TAHMİNİ ile grafikleri hazırlar ve işinizi BÜYÜTÜR!',
            paragraphText2: 'Marketinizde ürün, pazar ve müşteri trendlerini belirler. Yapay Zeka algoritmalarıyla analizlerini rahatlıkla yapar ve size sunar. Marketinizde trend ne yöne gidiyor? Yapay zeka algoritmalarımızla hazırlanan grafiklerle karar verin, hızlı hareket sağlayın. Pazarda en çok tercih edilen ürünler neler? Grafikler ile analiz edin. Takip edin. Karar verin. Her zaman önde olun.',
            title2: 'Gerekler',
            title3: 'Trendictor - Uygulama Alanları',
            title4: 'Kullandığımız Teknolojiler',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
        }
    ]
    servicesDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: 'Veri Bilimi'
        },
        {
            title: 'Yapay Zeka Algoritmalar'
        },
        {
            title: 'Matematik - İstatistikler'
        },
        {
            title: 'Predictive Modeller'
        },
        {
            title: 'Decision Modeller'
        },
        {
            title: 'Analitik Teknikler'
        }
    ]
    servicesDetailsDescApplication: Application[] = [
        {
            title: 'Fabrikalar',
            icon: 'flaticon-factory'
        },
        {
            title: 'Sağlık',
            icon: 'flaticon-hospital'
        },
        {
            title: 'Otomobil',
            icon: 'flaticon-tracking'
        },
        {
            title: 'Finans',
            icon: 'flaticon-investment'
        },
        {
            title: 'Emlak',
            icon: 'flaticon-house'
        },
        {
            title: 'Lojistik',
            icon: 'flaticon-order'
        },
        {
            title: 'E-ticaret',
            icon: 'flaticon-money'
        },
        {
            title: 'Sigortacılık',
            icon: 'flaticon-family-insurance'
        },
        {
            title: 'Turizm',
            icon: 'flaticon-tracking'
        },
        {
            title: 'Medya-TV',
            icon: 'flaticon-segmentation'
        },
        {
            title: 'Sosyal Medya',
            icon: 'flaticon-settings'
        },
        {
            title: 'Reklam',
            icon: 'flaticon-idea'
        }

    ]
    servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
        {
            title: 'JavaScript'
        },
        {
            title: 'Python'
        },
        {
            title: 'Java'
        },
        {
            title: 'C/CPP'
        },
        {
            title: 'PHP'
        },
        {
            title: 'Swift'
        },
        {
            title: 'C# (C- Sharp)'
        },
        {
            title: 'Ruby'
        },
        {
            title: 'SQL'
        },
        {
            title: 'R Programming'
        },
        {
            title: 'LISP'
        },
        {
            title: 'Prolog'
        },
        {
            title: 'Haskell'
        },
        {
            title: 'Julia'
        },
        {
            title: 'Go'
        }
    ]

    sidebarServicesList: ServicesList[] = [
        {
            title: 'TREND Yönü Analizleri',
            link: 'services-details'
        },
        {
            title: 'Data Analizleri',
            link: 'services-details'
        },
        {
            title: 'Veri Bilimi',
            link: 'services-details'
        },
        {
            title: 'Yapay Zeka',
            link: 'services-details'
        },
        {
            title: 'AI & ML Geliştirme',
            link: 'services-details'
        },
        {
            title: 'Veri Görselleştirme',
            link: 'services-details'
        }
    ]
    
    sidebarDownloadFile: DownloadFile[] = [
        {
            title: 'PDF Download',
            icon: 'bx bxs-file-pdf',
            link: 'services-details'
        },
        {
            title: 'Services Details.txt',
            icon: 'bx bxs-file-txt',
            link: 'services-details'
        }
    ]
    sidebarContactInfo: ContactInfo[] = [
        {
            icon: 'bx bx-user-pin',
            title: 'Telefon',
            subTitle: '+90533 451 3512'
        },
        {
            icon: 'bx bx-map',
            title: 'Adres',
            subTitle: 'Ümraniye, İstanbul - TÜRKİYE'
        },
        {
            icon: 'bx bx-envelope',
            title: 'Email',
            subTitle: 'contact@trendictor.com'
        }
    ]
    
}
class pageTitle {
    title : string;
    subTitle : string;
}
class DetailsImage {
    img : string;
}
class DetailsDesc {
    subTitle : string;
    title1 : string;
    title2 : string;
    title3 : string;
    title4 : string;
    paragraphText1 : string;
    paragraphText2 : string;
    img1 : string;
    img2 : string;
}
class ImportantFacts {
    title : string;
}
class Application {
    title : string;
    icon: string;
}
class TechnologiesFeatures {
    title : string;
}

class ServicesList {
    title : string;
    link : string;
}
class DownloadFile {
    title : string;
    icon : string;
    link : string;
}
class ContactInfo {
    icon : string;
    title : string;
    subTitle : string;
}