import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-process',
    templateUrl: './homeone-process.component.html',
    styleUrls: ['./homeone-process.component.scss']
})
export class HomeoneProcessComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Nasıl Çalışır?",
            title: 'TRENDICTOR İş Akışımız',
            paragraphText: 'Büyük veri işlenerek Yapay Zeka Algoritmalarımızdan geçirilir.'
        }
    ]
    singleProcessBox: processBoxContent[] = [
        {
            img: 'assets/img/process/img1.png',
            title: 'Müşteriyi Dinleme',
            paragraphText: 'Müşterinin trend yönünü tahmin etmek istediği pazar, ürünler veya müşterisi hakkında düşünceleri dinlenir. Problem çerçevesi belirlenir.',
            number: '1'
        },
        {
            img: 'assets/img/process/img2.png',
            title: 'Veriyi Alma',
            paragraphText: 'Büyük veri toplanır veya elde edilerek işleme başlanır.',
            number: '2'
        },
        {
            img: 'assets/img/process/img3.png',
            title: 'Veriyi İşleme',
            paragraphText: 'Elde edilen büyük veri üzerinde işleme çalışmaları ile doğru veri, yanlış veri veya tutarsız veriler belirlenir.',
            number: '3'
        },
        {
            img: 'assets/img/process/img4.png',
            title: 'Yapay Zeka Algoritma ile veriyi derin inceleme',
            paragraphText: 'Yapay Zeka algoritmalarımızdan veri geçirilerek derin incelemeleri yapılır.',
            number: '4'
        },
        {
            img: 'assets/img/process/img5.png',
            title: 'Yapay Zeka veri analizi ile TREND YÖN TAHMİN grafiklerini hazırlama',
            paragraphText: 'Algoritmalardan geçen veriler ile Trend yön tahmin analizleri ve grafikleri çıkarılır.',
            number: '5'
        },
        {
            img: 'assets/img/process/img6.png',
            title: 'Sonuçları Değerlendirme',
            paragraphText: 'Sonuçlar müşterilerle beraber değerlendirilir. Yönetim paneli üzerinden kendi verilerini ve analiz sonuçlarını izler, değerlendirir ve aksiyonlar alır.',
            number: '6'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class processBoxContent {
    img : string;
    title : string;
    paragraphText : string;
    number : string;
}