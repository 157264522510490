import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-case-studies',
    templateUrl: './homeone-case-studies.component.html',
    styleUrls: ['./homeone-case-studies.component.scss']
})
export class HomeoneCaseStudiesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    singleCaseStudyItem: singleCaseStudyItemContent[] = [
        {
            subTitle: 'Case study #1',
            title: 'Sağlık Alanında TRENDICTOR kullanımı',
            paragraphText1: 'Geleceği tahmin etme gücü, ilaç keşfi ve olumsuz sonuçlardan kaçınma uygulamalarına sahiptir. Süreci hızlandırmak için ilaç şirketleri, moleküler ve klinik verilerin devasa sanal veritabanlarını aramak için tahmine dayalı modeller kullanıyor.',
            paragraphText2: 'Tahmine dayalı analitik, genetik kümeleme yoluyla optimal hastaların seçilmesi yoluyla klinik deneyleri optimize etmek ve pazarlama çabalarını iyileştirmek için de kullanılabilir.',
            link: 'case-studies-details',
            linkText: 'Detaylı Bilgi',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/img1.jpg'
        },
        {
            subTitle: 'Case study #2',
            title: 'Python İleri Matematik ve İstatistik kullanımı',
            paragraphText1: 'Büyük veri ve yapay zeka çağında, veri bilimi ve makine öğrenimi, bilim ve teknolojinin birçok alanında vazgeçilmez hale geldi. Verilerle çalışmanın gerekli bir yönü, verileri görsel olarak tanımlama, özetleme ve temsil etme yeteneğidir. Python istatistik kitaplıkları, verilerle çalışmanıza yardımcı olacak kapsamlı, popüler ve yaygın olarak kullanılan araçlardır.',
            paragraphText2: 'Bir popülasyonun bu alt kümesine örneklem denir. İdeal olarak, örnek popülasyonun temel istatistiksel özelliklerini tatmin edici bir ölçüde korumalıdır. Bu şekilde, popülasyon hakkında sonuçlar çıkarmak için örneği kullanabileceksiniz.',
            link: 'case-studies-details',
            linkText: 'Detaylı Bilgi',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/img2.jpg'
        }
    ]

}
class singleCaseStudyItemContent {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
    link : string;
    linkText : string;
    linkIcon : string;
    img : string;
}