import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-solutions',
    templateUrl: './solutions.component.html',
    styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Hizmetlerimiz',
            title: 'Neden Trendictor?',
            paragraphText: 'Yapay Zeka Algoritmaları kullanarak pazarlar, satışlar, müşteriler, e-ticaret, lojistik analizlerini yaparak trend yönünüzü belirler!'
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'flaticon-rocket',
            title: 'TREND Yönü Analizleri',
            paragraphText: 'Marketinizde ürün, pazar ve müşteri trendlerini belirler. Yapay Zeka algoritmalarıyla analizlerini rahatlıkla yapar ve size sunar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'flaticon-laptop',
            title: 'Pazar-Marketing Analizleri',
            paragraphText: 'Pazar ve marketinizde trend yönü belirleyici analizleri yapay zeka algoritmalarıyla hazırlar. Büyük veriyi işleyerek trend yönünü takip eder.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        },
        {
            icon: 'flaticon-money',
            title: 'Müşteri Analizleri',
            paragraphText: 'Pazarınızdaki müşterilerin trend yönünü yapay zeka algoritmalarıyla hazırlar. Müşterilerinizi kendinize çekmenizi sağlayacak trendi grafiklerle size gösterir.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi'
        }   
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}