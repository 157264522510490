import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-aboutpage-about',
    templateUrl: './aboutpage-about.component.html',
    styleUrls: ['./aboutpage-about.component.scss']
})
export class AboutpageAboutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    aboutImage: Image[] = [
        {
            img: 'assets/img/about/img1.png'
        }
    ]
    aboutContent: Content[] = [
        {
            subTitle: 'HAKKIMIZDA',
            title: 'Yapay Zeka kullanan TRENDICTOR ile işinizi büyütün',
            paragraphText1: 'Marketinizde trend ne yöne gidiyor? Yapay zeka algoritmalarımızla hazırlanan grafiklerle karar verin, hızlı hareket sağlayın.',
            paragraphText2: 'Pazarda en çok tercih edilen ürünler neler? Grafikler ile analiz edin. Takip edin. Karar verin. Her zaman önde olun.',
        }
    ]
    featuresList: List[] = [
        {
            icon: 'assets/img/icon1.png',
            title: '21 Yıllık',
            subTitle: 'Tecrübe'
        },
        {
            icon: 'assets/img/icon2.png',
            title: '5+',
            subTitle: 'Ekip Üyemiz'
        },
        {
            icon: 'assets/img/icon3.png',
            title: '100%',
            subTitle: 'Memnuniyet'
        },
        {
            icon: 'assets/img/icon4.png',
            title: '80%',
            subTitle: 'Veri Bilimci'
        }
    ]
    aboutText: Text[] = [
        {
            title: 'Tarihçe',
            paragraphText: '25 yıllık IT yazılım sektöründe ki tecrübelerimiz ile Bankacılık, Finans kurumları, Telekomünikasyon, Belediye, Tekstil, Turizm, Sağlık ve Lojistik şirketlerindeki geliştirdiğimiz başarılı projelerimizle bu alanda da katma değer servisler sunmaya devam ediyoruz.',
            featuresList1: 'Kavramlaştırma',
            featuresList2: 'Markalaştırma',
            featuresList3: 'Veri Bilimi',
            featuresList4: 'Yazılım Geliştirme',
            icon: 'flaticon-tick'
        },
        {
            title: 'Görevimiz',
            paragraphText: 'Yapay Zeka ile ileri seviye uygulama geliştirerek sektöre değer katmayı amaç ediniyoruz. Yapay zeka, veri bilimi ve makine öğrenmesi üzerine geliştirmelerde bulunarak teknolojiye yön vermeyi hedefliyoruz.',
            featuresList1: 'Global Tecrübe',
            featuresList2: 'Müşteri Deneyimi',
            featuresList3: 'İşinizi Büyütmek',
            featuresList4: 'Yüksek Kalite Sonuçlar',
            icon: 'flaticon-tick'
        },
        {
            title: 'Biz Kimiz',
            paragraphText: 'Veri Bilimi üzerine yapay zeka uygulamaları geliştiriyoruz. Son teknoloji alt yapımız ve güvenlik konusunda hassasiyetimizle ileri seviyede yazılım ve sistem mimarisi kullanarak daima en iyilerini hizmetinize sunmak için buradayız.',
            featuresList1: 'Kaliteli Ekip',
            featuresList2: 'Eleştirisel Düşünce',
            featuresList3: 'Büyük Veri Analiz',
            featuresList4: 'Teknolojiye Yön Veren',
            icon: 'flaticon-tick'
        }
    ]

}
class Image {
    img : string;
}
class Content {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
}
class List {
    icon : string;
    title : string;
    subTitle : string;
}
class Text {
    title : string;
    paragraphText : string;
    featuresList1 : string;
    featuresList2 : string;
    featuresList3 : string;
    featuresList4 : string;
    icon : string;
}