import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-services',
    templateUrl: './homeone-services.component.html',
    styleUrls: ['./homeone-services.component.scss']
})
export class HomeoneServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Hizmetlerimiz',
            title: 'İş Dünyası için Profesyonel Çözümler Sunuyoruz',
            paragraphText: 'Market, ürün, müşteri ve satış trend yönünü yapay zeka algoritmalarımızla belirleyin. Geleceğinizi güvenle çizin.'
        }
    ]
    singleServicesBox: ServicesBoxContent[] = [
        {
            icon: 'assets/img/services/icon1.png',
            title: 'Trend Yönü Analizleri',
            paragraphText: 'Marketinizde ki ürün, pazar ve müşteri trendlerini yapay zeka algoritmalarıyla analizlerini yapar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon2.png',
            title: 'Pazar-Marketing Analizleri',
            paragraphText: 'Pazar ve marketinizde trend yönü belirleyici analizleri yapay zeka algoritmalarıyla hazırlar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon3.png',
            title: 'Müşteri Analizleri',
            paragraphText: 'Pazarınızdaki müşterilerin trend yönünü yapay zeka algoritmalarıyla analizlerini hazırlar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon4.png',
            title: 'Eticaret Analizleri',
            paragraphText: 'Eticaret ürünlerinizin, satışlarınızın trendini belirler. Size uygun ürünlerle satışınızı nasıl arttıracağınızı planlar.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon5.png',
            title: 'Satış Analizleri',
            paragraphText: 'Pazarınızda ki satışını yaptığınız ürünlerde trend hangi yöne gidiyor bunu belirler. Stratejinizle doğru hamleler yaptırır.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon6.png',
            title: 'Risk Analizleri',
            paragraphText: 'Sektörenizde ki risklerin analizlerini yapay zeka algoritmalarıyla yapar. Risklerin trendini size gösterir.',
            link: 'services-details',
            linkText: 'Detaylı Bilgi',
            linkIcon: 'flaticon-right'
        }
    ]
    loadMoreBtn: loadMore[] = [
        {
            link: 'services',
            linkText: 'Daha Fazla',
            linkIcon: 'flaticon-refresh'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class ServicesBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
    linkIcon : string;
}
class loadMore {
    link : string;
    linkText : string;
    linkIcon : string;
}