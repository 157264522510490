import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'İletişim'
        }
    ]
    contactInfoBox1: InfoBox1[] = [
        {
            icon: 'bx bx-map',
            title: 'Adres',
            location: 'Esenkent, Dudullu OSB, DES, 2. Cd. No:8/41 34776 Ümraniye/İstanbul.'
        }
    ]
    contactInfoBox2: InfoBox2[] = [
        {
            icon: 'bx bx-phone-call',
            title: 'İletişim',
            number: '(+90) 533 451 3512',
            email: 'contact@trendictor.com'
        }
    ]
    contactInfoBox3: InfoBox3[] = [
        {
            icon: 'bx bx-time-five',
            title: 'Çalışma Saatlerimiz',
            text1: 'Pazartesi - Cuma:  09:00 - 18:00',
            text2: 'Cumartesi - Pazar: 10:00 - 17:00'
        }
    ]

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "İletişim",
            title: 'Haydi Başlayalım!',
            paragraphText: '*Gerekli alanları lütfen giriniz.'
        }
    ]
    contactImage: Image[] = [
        {
            img: 'assets/img/contact.png'
        }
    ]

}
class pageTitle {
    title : string;
}
class InfoBox1 {
    icon : string;
    title : string;
    location : string;
}
class InfoBox2 {
    icon : string;
    title : string;
    number : string;
    email : string;
}
class InfoBox3 {
    icon : string;
    title : string;
    text1 : string;
    text2 : string;
}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class Image {
    img : string;
}